import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WebNavbar from "../components/web_navbar";
import "react-chatbot-kit/build/main.css";
import { createClientMessage } from "react-chatbot-kit";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (accessToken) {
      navigate("/dashboard");
    }

    window.scrollTo(0, 0);
    createClientMessage("Hello there.");
  }, [navigate]);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <section className="section">
            <h1 className="title">Struts License Manager</h1>
            <h2 className="subtitle">
              Licenses management within the Struts Technology ecosystem.
            </h2>

            <br />
            <br />

            <Link className="navbar-item" to="/">
              <img
                src="/assets/logo.png"
                alt="Struts License Manager"
                style={{ maxHeight: "5.50rem" }}
              />
            </Link>

            <br />

            <p>
              Powered by{" "}
              <a
                href="https://www.strutstechnology.co.ke"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#00d1b2" }}
              >
                Struts Technology
              </a>
            </p>
          </section>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
};

export default Home;
