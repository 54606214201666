import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Chat = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const [chat, setChat] = useState({});

  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var url = apiBaseURL + "/chats/" + id;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let chatData = JSON.parse(JSON.stringify(response.data.chat));
        setChat(chatData);
      })
      .catch((err) => console.error("fetchInvoices err = " + err.message));
  }, [apiBaseURL, id, navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Chat #{chat.chat_number}</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-50px" }}
            >
              <nav
                className="breadcrumb has-arrow-separator"
                aria-label="breadcrumbs"
                style={{ fontSize: "9pt" }}
              >
                <ul>
                  <li>
                    <Link
                      to="/dashboard"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/chats"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Chats
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/chats/" + id}
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      View Chat
                    </Link>
                  </li>
                </ul>
              </nav>
              <p>
                <b>{chat.input_text}</b>
              </p>
              <br />
              Input Text: {chat.input_text}
              <br />
              <br />
              Response: {chat.response}
              <br />
              <br />
              Date: {chat.created_at}
              <br />
              <br />
              <br />
              {/* Enable where pdf files are involved, not needed for API signing. */}
              {/* <iframe
                src={pdfFile}
                title="testPdf"
                height="900px"
                width="100%"
              /> */}
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Chat;
