import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Invoice = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const [invoice, setInvoice] = useState({});

  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  // var pdfFile = apiBaseURL + "/invoices/" + id + "/pdf";

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var url = apiBaseURL + "/invoices/" + id;

    const config = {
      headers: {
        "x-strutsai-token": accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let invoiceData = JSON.parse(JSON.stringify(response.data));
        setInvoice(invoiceData);
      })
      .catch((err) => console.error("fetchInvoices err = " + err.message));
  }, [apiBaseURL, id, navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Invoice #{invoice.invoice_number}</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <p>
                <b>Invoice #{invoice.invoice_number}</b>
              </p>
              <br />
              Invoice Number: {invoice.invoice_number}
              <br />
              Gross Amount: {invoice.gross_amount}
              <br />
              Vat: {invoice.vat}
              <br />
              Grand Total: {invoice.grand_total}
              <br />
              Signature: {invoice.signature}
              <br />
              Verification URL:{" "}
              <a
                href={invoice.verification_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {invoice.verification_url}
              </a>
              <br />
              <br />
              {/* Enable where pdf files are involved, not needed for API signing. */}
              {/* <iframe
                src={pdfFile}
                title="testPdf"
                height="900px"
                width="100%"
              /> */}
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Invoice;
