import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const VFDConfiguration = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [createdAt, setCreatedAt] = useState("");

  const getUser = async () => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("struts_lm_access_token");
    var userID = localStorage.getItem("struts_lm_user_id");

    try {
      const res = await axios({
        url: apiBaseURL + "/users/" + userID,
        headers: {
          "x-strutsai-token": accessToken,
          "x-strutsai-application": "user",
        },
        method: "GET",
      });

      let userData = JSON.parse(JSON.stringify(res.data));
      setUser(userData);
      setCreatedAt(userData.created_at.substring(0, 10));
    } catch (err) {
      console.error("getUser err = " + err.message);
    }
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (!accessToken) {
      navigate("/");
    }

    getUser();

    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">VFD Configuration</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <p>
                <b>VFD Configuration</b>
              </p>
              <br />
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Business Name</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Business Name"
                      />
                    </div>
                  </div>

                  <div className="file" style={{ marginBottom: "8px" }}>
                    <label className="file-label">
                      <input className="file-input" type="file" name="cert" />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">Choose certificate…</span>
                      </span>
                    </label>
                  </div>
                  {/* <br /> */}
                  <div className="field">
                    <label className="label">TIN Number</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="TIN Number"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Cert Key</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Cert Key"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Cert Serial</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Cert Serial"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <button className="button is-link">Save</button>
                  </div>
                </div>
                <div className="column">
                  Email: {user.email}
                  <br />
                  Created At: {createdAt}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VFDConfiguration;
