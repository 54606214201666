import React from "react";
import { Link } from "react-router-dom";

const AdminSideMenu = () => {
  return (
    <>
      <aside className="menu" style={{ padding: "10px" }}>
        <p className="menu-label">Dashboard</p>
        <ul className="menu-list">
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
        </ul>
        <p className="menu-label">Clients</p>
        <ul className="menu-list">
          <li>
            <Link to="/clients">Clients</Link>
          </li>
        </ul>
        <p className="menu-label">Analytics</p>
        <ul className="menu-list">
          <li>
            <Link to="/analytics">Analytics</Link>
          </li>
        </ul>
        <p className="menu-label">Settings</p>
        <ul className="menu-list">
          <li>
            <Link to="/profile">Profile</Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default AdminSideMenu;
