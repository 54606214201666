import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import "react-tooltip/dist/react-tooltip.css";
import AITrainingBreadCrumps from "../shared/Navigation";

const AITraining = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [count, setCount] = useState(0);
  const [aiTrainings, setAITraining] = useState([]);
  const [per, setPer] = useState(8);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    setPer(8);

    var url = apiBaseURL + "/ai-training?per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let responseData = JSON.parse(JSON.stringify(response.data));
        let chatsCount = responseData.pagination.count;
        setCount(chatsCount);
        setAITraining(responseData.ai_trainings);

        let numPages = Math.ceil(chatsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("fetchAITrainings err = " + err.message));
  }, [apiBaseURL, per, navigate]);

  const searchAITrainings = (searchTermText) => {
    var accessToken = localStorage.getItem("struts_lm_access_token");
    var url = apiBaseURL + "/ai-training?search=" + searchTermText;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let responseData = JSON.parse(JSON.stringify(response.data));
        let chatsCount = responseData.pagination.count;
        setCount(chatsCount);
        setAITraining(responseData.ai_trainings);

        let numPages = Math.ceil(chatsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("searchAITrainings err = " + err.message));
  };

  const handlePageClick = (data) => {
    filterAITrainings(data);
  };

  const filterAITrainings = (data) => {
    var selectedPage = data.selected + 1;
    var accessToken = localStorage.getItem("struts_lm_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url = apiBaseURL + "/ai-training?page=" + selectedPage + "&per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let responseData = JSON.parse(JSON.stringify(response.data));
        let chatsCount = responseData.pagination.count;
        setCount(chatsCount);
        setAITraining(responseData.ai_trainings);

        let numPages = Math.ceil(chatsCount / per);
        setPageCount(numPages);
      })
      .catch((err) =>
        console.error("filter AI Trainings err = " + err.message)
      );
  };

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title" style={{ color: "#404040" }}>
            AI Training
          </h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>

            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-50px" }}
            >
              <AITrainingBreadCrumps />

              <div className="columns">
                <div className="column">
                  <b>AI Training ({count})</b>
                  &nbsp;&nbsp;
                  <Link
                    to="/ai-training-documentation"
                    style={{
                      color: "#00d1b2",
                      fontWeight: "bold",
                      fontSize: "9pt",
                    }}
                  >
                    Info
                  </Link>
                </div>
                <div className="column"></div>
                <div className="column"></div>
                <div className="column">
                  <div
                    className="bd-notification2 is-primary is-pulled-right"
                    data-tooltip="Create AI Training"
                  >
                    <Link to="/ai-training/new">
                      <button className="button is-link">
                        Create AI Training
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchAITrainings(e.target.value)}
                />
              </p>
              <br />
              <table
                className="table is-striped is-hoverable"
                style={{ width: "100%" }}
              >
                <thead style={{ backgroundColor: "#D0D0D0" }}>
                  <tr>
                    <th>Title</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tfoot style={{ backgroundColor: "#D0D0D0" }}>
                  <tr>
                    <th>Title</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </tfoot>
                <tbody>
                  {aiTrainings.map((ai_training) => (
                    <tr key={ai_training.id}>
                      <th>{ai_training.title}</th>
                      <td>{ai_training.created_at}</td>
                      <th>
                        <Link
                          to={{ pathname: `/ai-training/${ai_training.id}` }}
                          data-tooltip="View"
                        >
                          <span
                            className="icon has-text-primary"
                            data-tooltip="View"
                          >
                            <i
                              className="fas fa-eye is-primary"
                              data-tooltip="View"
                            ></i>
                          </span>
                        </Link>
                        &nbsp;
                        <Link
                          to={{ pathname: `/ai-training/${ai_training.id}` }}
                        >
                          <span className="icon">
                            <i className="fas fa-edit"></i>
                          </span>
                        </Link>
                        &nbsp;
                        <Link
                          to={{ pathname: `/ai-training/${ai_training.id}` }}
                        >
                          <span className="icon has-text-danger">
                            <i className="fas fa-trash"></i>
                          </span>
                        </Link>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="container">
                <nav
                  className="pagination is-centered"
                  role="navigation"
                  aria-label="pagination"
                >
                  <ReactPaginate
                    previousLabel={"Previous"}
                    previousClassName={"pagination-previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"pagination-ellipsis"}
                    pageCount={pageCount}
                    marginPagkodiisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination-list"}
                    subContainerClassName={""}
                    activeClassName={"pagination-link is-current"}
                    pageClassName=""
                    pageLinkClassName="pagination-link"
                    disabledClassName="disabled"
                    nextClassName="pagination-next"
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AITraining;
