import React from "react";
import { Link } from "react-router-dom";

const DashboardAnalytics = (
  chatsCount,
  aiTrainingsCount,
  apiKeysCount,
  chatbotsCount
) => {
  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <article className="tile is-child box transparent">
            <Link to="/clients">
              <p className="title">{chatsCount}</p>
              <p className="subtitle">Clients</p>
            </Link>
          </article>
        </div>
        <div className="tile is-parent">
          <article className="tile is-child box">
            <Link to="/clients">
              <p className="title has-text-info">{aiTrainingsCount}</p>
              <p className="subtitle">Users</p>
            </Link>
          </article>
        </div>
        <div className="tile is-parent">
          <article className="tile is-child box">
            <Link to="/clients">
              <p className="title has-text-primary">{apiKeysCount}</p>
              <p className="subtitle">Licenses</p>
            </Link>
          </article>
        </div>

        <div className="tile is-parent">
          <article className="tile is-child box accent">
            <Link to="/clients">
              <p className="title has-text-warning">{chatbotsCount}</p>
              <p className="subtitle">Nearing Expiry</p>
            </Link>
          </article>
        </div>
      </div>
    </>
  );
};

export default DashboardAnalytics;
