import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "../../components/admin_navbar";
import AdminSideMenu from "../../components/admin_side_menu";
import BarChart from "../../components/bar_chart";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ContentHeader from "./shared/ContentHeader";
import DashboardAnalytics from "./shared/DashboardAnalytics";
import RecentClients from "./clients/RecentClients";

const Analytics = () => {
  const navigate = useNavigate();

  let dashboardAnalytics = useRef();
  let contentHeader = useRef();

  const [clients, setRecentClients] = useState([]);
  const [chartData, setChartData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    contentHeader.current = ContentHeader(
      "Analytics",
      "/analytics-documentation",
      "System analytics"
    );

    const getDashboardAnalytics = async () => {
      setIsLoading(true);
      var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
      var accessToken = localStorage.getItem("struts_lm_access_token");

      try {
        const response = await axios({
          url: apiBaseURL + "/analytics/dashboard",
          headers: {
            Authorization: "Bearer " + accessToken,
            "x-strutsai-application": "user",
          },
          method: "GET",
        });

        if (response.status === 401 || response.status === 403) {
          navigate("/");
        }

        let analyticsData = JSON.parse(JSON.stringify(response.data));
        setRecentClients(analyticsData.recent_clients);

        dashboardAnalytics.current = DashboardAnalytics(
          analyticsData.clientsCount,
          analyticsData.usersCount,
          analyticsData.licensesCount,
          analyticsData.licenesNearingExpiryCount
        );

        let chartSummariesData = analyticsData.chart_summary;

        setChartData({
          labels: chartSummariesData.map((data) => data.month),
          datasets: [
            {
              label: "Clients",
              data: chartSummariesData.map((data) => data.total_clients),
            },
          ],
        });

        setIsLoading(false);
      } catch (err) {
        console.error("getDashboardAnalytics err = " + err);
      }
    };

    getDashboardAnalytics();
  }, [navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title" style={{ color: "#404040" }}>
            Analytics
          </h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-50px" }}
            >
              {contentHeader.current}

              {dashboardAnalytics.current}

              <br />
              <hr
                style={{
                  height: "1px",
                  backgroundColor: " #ccc",
                  border: "none",
                }}
              />

              <br />

              <div className="tile is-ancestor">
                {/* <PieChart chartData={chartData} /> */}

                {/* <LineChart
                  labels={chartData.labels}
                  datasets={chartData.datasets}
                /> */}

                {/*
                <Pie
                  data={barChartConfig.data}
                  options={barChartConfig.options}
                /> */}

                {/* {chartRender} */}

                {/* <Bar data={chartData} /> */}

                {chartData && (
                  <BarChart
                    labels={chartData.labels}
                    datasets={chartData.datasets}
                  />
                )}
              </div>

              <RecentClients clients={clients} isLoading={isLoading} />
              <br />
              <br />

              {/* {{.bar}} */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Analytics;
