import React from "react";
import { Link } from "react-router-dom";

export default function AITrainingBreadCrumps() {
  return (
    <>
      <nav
        className="breadcrumb has-arrow-separator"
        aria-label="breadcrumbs"
        style={{ fontSize: "9pt" }}
      >
        <ul>
          <li>
            <Link
              to="/dashboard"
              style={{
                color: "#00d1b2",
                fontWeight: "bold",
                fontSize: "9pt",
              }}
            >
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/ai-training"
              style={{
                color: "#00d1b2",
                fontWeight: "bold",
                fontSize: "9pt",
              }}
            >
              AI Training
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}
