import React, { useEffect } from "react";
import AdminNavbar from "../../components/admin_navbar";
import AdminSideMenu from "../../components/admin_side_menu";
import XeroConfiguration from "../../components/xero_configuration";
import { useNavigate } from "react-router-dom";

const XeroSetup = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
  }, [apiBaseURL, navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Configurations</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <XeroConfiguration />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default XeroSetup;
