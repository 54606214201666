import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "../../components/admin_navbar";
import AdminSideMenu from "../../components/admin_side_menu";
import ContentHeader from "./shared/ContentHeader";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Profile = () => {
  const navigate = useNavigate();

  let contentHeader = useRef();

  const [user, setUser] = useState({});
  const [createdAt, setCreatedAt] = useState("");

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (!accessToken) {
      navigate("/");
    }

    const getUser = async () => {
      var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
      var accessToken = localStorage.getItem("struts_lm_access_token");
      var userID = localStorage.getItem("struts_lm_user_id");

      try {
        const response = await axios({
          url: apiBaseURL + "/users/" + userID,
          headers: {
            Authorization: "Bearer " + accessToken,
            "x-strutsai-application": "user",
          },
          method: "GET",
        });

        if (response.status === 401 || response.status === 403) {
          navigate("/");
        }

        let userData = JSON.parse(JSON.stringify(response.data));
        setUser(userData);
        setCreatedAt(userData.createdAt.substring(0, 10));

        contentHeader.current = ContentHeader(
          "User Profile",
          "/profile-documentation",
          userData.first_name + " " + userData.last_name
        );
      } catch (err) {
        console.error("getUser err = " + err.message);
      }
    };

    getUser();

    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title" style={{ color: "#404040" }}>
            Profile
          </h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "2px", marginTop: "-50px" }}
            >
              {contentHeader.current}
              <p>
                <b>{user.first_name}'s Profile</b>
              </p>
              <br />
              First Name: {user.first_name}
              <br />
              Last Name: {user.last_name}
              <br />
              Email: {user.email}
              <br />
              Created At: {createdAt}
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
