import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";

const Chats = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [count, setCount] = useState(0);
  const [chats, setChats] = useState([]);
  const [per, setPer] = useState(8);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    setPer(8);

    var url = apiBaseURL + "/chats?per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let chatsData = JSON.parse(JSON.stringify(response.data));
        let chatsCount = chatsData.pagination.count;
        setCount(chatsCount);
        setChats(chatsData.chats);

        let numPages = Math.ceil(chatsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("fetchChats err = " + err.message));
  }, [apiBaseURL, per, navigate]);

  const searchChats = (searchTermText) => {
    var accessToken = localStorage.getItem("struts_lm_access_token");
    var url = apiBaseURL + "/chats?search=" + searchTermText;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let chatsData = JSON.parse(JSON.stringify(response.data));
        let chatsCount = chatsData.pagination.count;
        setCount(chatsCount);
        setChats(chatsData.chats);

        let numPages = Math.ceil(chatsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("searchChats err = " + err.message));
  };

  const handlePageClick = (data) => {
    filterChats(data);
  };

  const filterChats = (data) => {
    var selectedPage = data.selected + 1;
    var accessToken = localStorage.getItem("struts_lm_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url = apiBaseURL + "/chats?page=" + selectedPage + "&per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let chatsData = JSON.parse(JSON.stringify(response.data));
        let chatsCount = chatsData.pagination.count;
        setCount(chatsCount);
        setChats(chatsData.chats);

        let numPages = Math.ceil(chatsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter Chats err = " + err.message));
  };

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title" style={{ color: "#404040" }}>
            Conversations
          </h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <div style={{ marginBottom: "12px", marginTop: "-50px" }}>
                <nav
                  className="breadcrumb has-arrow-separator"
                  aria-label="breadcrumbs"
                  style={{ fontSize: "9pt" }}
                >
                  <ul>
                    <li>
                      <Link
                        to="/dashboard"
                        style={{
                          color: "#00d1b2",
                          fontWeight: "bold",
                          fontSize: "9pt",
                        }}
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/chats"
                        style={{
                          color: "#00d1b2",
                          fontWeight: "bold",
                          fontSize: "9pt",
                        }}
                      >
                        Conversations
                      </Link>
                    </li>
                  </ul>
                </nav>
                <b>Conversations ({count})</b>
                &nbsp;&nbsp;
                <Link
                  to="/chats-documentation"
                  style={{
                    color: "#00d1b2",
                    fontWeight: "bold",
                    fontSize: "9pt",
                  }}
                >
                  Info
                </Link>
              </div>

              <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchChats(e.target.value)}
                />
              </p>
              <br />
              <table className="table is-striped is-hoverable">
                <thead style={{ backgroundColor: "#D0D0D0" }}>
                  <tr>
                    <th>Input Text</th>
                    <th>Response</th>
                    <th>Token Usage</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tfoot style={{ backgroundColor: "#D0D0D0" }}>
                  <tr>
                    <th>Input Text</th>
                    <th>Response</th>
                    <th>Token Usage</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </tfoot>
                <tbody>
                  {chats.map((chat) => (
                    <tr key={chat.id}>
                      <th>{chat.input_text}</th>
                      <td>{chat.response}</td>
                      <td>{chat.llm_token_usage}</td>
                      <td>{chat.created_at}</td>
                      <th>
                        <Link to={{ pathname: `/chats/${chat.id}` }}>
                          <span
                            className="icon has-text-primary"
                            data-tooltip="View"
                          >
                            <i
                              className="fas fa-eye is-primary"
                              data-tooltip="View"
                            ></i>
                          </span>
                        </Link>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="container">
                <nav
                  className="pagination is-centered"
                  role="navigation"
                  aria-label="pagination"
                >
                  <ReactPaginate
                    previousLabel={"Previous"}
                    previousClassName={"pagination-previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"pagination-ellipsis"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination-list"}
                    subContainerClassName={""}
                    activeClassName={"pagination-link is-current"}
                    pageClassName=""
                    pageLinkClassName="pagination-link"
                    disabledClassName="disabled"
                    nextClassName="pagination-next"
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Chats;
