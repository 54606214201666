import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";

const APIKeys = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [count, setCount] = useState(0);
  const [api_keys, setChats] = useState([]);
  const [per, setPer] = useState(8);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    setPer(8);

    var url = apiBaseURL + "/api_keys?per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let api_keysData = JSON.parse(JSON.stringify(response.data));
        let api_keysCount = api_keysData.pagination.count;
        setCount(api_keysCount);
        setChats(api_keysData.api_keys);

        let numPages = Math.ceil(api_keysCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("fetchChats err = " + err.message));
  }, [apiBaseURL, per, navigate]);

  const searchChats = (searchTermText) => {
    var accessToken = localStorage.getItem("struts_lm_access_token");
    var url = apiBaseURL + "/api_keys?search=" + searchTermText;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let api_keysData = JSON.parse(JSON.stringify(response.data));
        let api_keysCount = api_keysData.pagination.count;
        setCount(api_keysCount);
        setChats(api_keysData.api_keys);

        let numPages = Math.ceil(api_keysCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("searchChats err = " + err.message));
  };

  const handlePageClick = (data) => {
    filterChats(data);
  };

  const filterChats = (data) => {
    var selectedPage = data.selected + 1;
    var accessToken = localStorage.getItem("struts_lm_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url = apiBaseURL + "/api_keys?page=" + selectedPage + "&per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let api_keysData = JSON.parse(JSON.stringify(response.data));
        let api_keysCount = api_keysData.pagination.count;
        setCount(api_keysCount);
        setChats(api_keysData.api_keys);

        let numPages = Math.ceil(api_keysCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter API Keys err = " + err.message));
  };

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title" style={{ color: "#404040" }}>
            API Keys
          </h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "2px", marginTop: "-50px" }}
            >
              <nav
                className="breadcrumb has-arrow-separator"
                aria-label="breadcrumbs"
                style={{ fontSize: "9pt" }}
              >
                <ul>
                  <li>
                    <Link
                      to="/dashboard"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/api-keys"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      API Keys
                    </Link>
                  </li>
                </ul>
              </nav>

              <div className="columns">
                <div className="column">
                  <b>API Keys ({count})</b>
                  &nbsp;&nbsp;
                  <Link
                    to="/api-keys-documentation"
                    style={{
                      color: "#00d1b2",
                      fontWeight: "bold",
                      fontSize: "9pt",
                    }}
                  >
                    Info
                  </Link>
                </div>
                <div className="column"></div>
                <div className="column"></div>
                <div className="column">
                  <div className="bd-notification2 is-primary is-pulled-right">
                    <Link to="/api-keys/create">
                      <button className="button is-link">
                        Generate an API Key
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchChats(e.target.value)}
                />
              </p>
              <br />
              <table
                className="table is-striped is-hoverable"
                style={{ width: "100%" }}
              >
                <thead style={{ backgroundColor: "#D0D0D0" }}>
                  <tr>
                    <th>Name</th>
                    <th>Key</th>
                    <th>Last Used</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tfoot style={{ backgroundColor: "#D0D0D0" }}>
                  <tr>
                    <th>Name</th>
                    <th>Key</th>
                    <th>Last Used</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </tfoot>
                <tbody>
                  {api_keys.map((api_key) => (
                    <tr key={api_key.id}>
                      <th>{api_key.name}</th>
                      <td>{api_key.key}</td>
                      <td>{api_key.last_used_at}</td>
                      <td>{api_key.created_at.substring(0, 10)}</td>
                      <th>
                        <Link to={{ pathname: `/api-keys/${api_key.id}` }}>
                          <span
                            className="icon has-text-primary"
                            data-tooltip="View"
                          >
                            <i
                              className="fas fa-eye is-primary"
                              data-tooltip="View"
                            ></i>
                          </span>
                        </Link>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="container">
                <nav
                  className="pagination is-centered"
                  role="navigation"
                  aria-label="pagination"
                >
                  <ReactPaginate
                    previousLabel={"Previous"}
                    previousClassName={"pagination-previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"pagination-ellipsis"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination-list"}
                    subContainerClassName={""}
                    activeClassName={"pagination-link is-current"}
                    pageClassName=""
                    pageLinkClassName="pagination-link"
                    disabledClassName="disabled"
                    nextClassName="pagination-next"
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default APIKeys;
