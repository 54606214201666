import React from "react";
import { Link } from "react-router-dom";

const RecentClients = (chats, isLoading) => {
  return (
    <>
      <p style={{ marginTop: "10px" }}>
        <b>Recent Clients</b>
      </p>
      <hr
        style={{
          height: "1px",
          backgroundColor: " #ccc",
          border: "none",
        }}
      />

      <table className="table is-striped is-hoverable">
        <thead style={{ backgroundColor: "#D0D0D0" }}>
          <tr>
            <th>Input Text</th>
            <th>Response</th>
            <th>Token Usage</th>
            <th>Created At</th>
            <th>View</th>
          </tr>
        </thead>
        <tfoot style={{ backgroundColor: "#D0D0D0" }}>
          <tr>
            <th>Input Text</th>
            <th>Response</th>
            <th>Token Usage</th>
            <th>Created At</th>
            <th>View</th>
          </tr>
        </tfoot>
        <tbody>
          {!isLoading &&
            chats.map((chat) => (
              <tr key={chat.id}>
                <th>{chat.input_text}</th>
                <td>{chat.response}</td>
                <td>{chat.llm_token_usage}</td>
                <td>{chat.created_at}</td>
                <th>
                  <Link to={{ pathname: `/chats/${chat.id}` }}>
                    <span className="icon has-text-primary" data-tooltip="View">
                      <i
                        className="fas fa-eye is-primary"
                        data-tooltip="View"
                      ></i>
                    </span>
                  </Link>
                </th>
              </tr>
            ))}
        </tbody>
      </table>
      <br />
    </>
  );
};

export default RecentClients;
