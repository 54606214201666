import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const AITraining = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const [aiTraining, setAITraining] = useState({});

  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var url = apiBaseURL + "/ai-training/" + id;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let aiTrainingData = JSON.parse(
          JSON.stringify(response.data.ai_training)
        );
        setAITraining(aiTrainingData);
      })
      .catch((err) => console.error("fetch AI Training err = " + err.message));
  }, [apiBaseURL, id, navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">AI Training</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-50px" }}
            >
              <nav
                className="breadcrumb has-arrow-separator"
                aria-label="breadcrumbs"
                style={{ fontSize: "9pt" }}
              >
                <ul>
                  <li>
                    <Link
                      to="/dashboard"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ai-training"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      AI Training
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/ai-training/" + id}
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      View AI Training
                    </Link>
                  </li>
                </ul>
              </nav>
              <p>
                <b>{aiTraining.title}</b>
              </p>
              <br />
              Training Data: {aiTraining.training_data}
              <br />
              <br />
              Date: {aiTraining.created_at}
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AITraining;
