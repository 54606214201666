import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "bulma-pagination-react";

const Clients = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [clients, setClients] = useState([]);
  const [per, setPer] = useState(8);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    setPer(8);

    var url = apiBaseURL + "/clients?per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          navigate("/");
        }
        let clientsData = JSON.parse(JSON.stringify(response.data));
        let clientsCount = clientsData.pagination.count;
        setCount(clientsCount);
        setClients(clientsData.clients);

        let numPages = Math.ceil(clientsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("fetchChats err = " + err.message));
  }, [apiBaseURL, per, navigate]);

  const searchChats = (searchTermText) => {
    var accessToken = localStorage.getItem("struts_lm_access_token");
    var url = apiBaseURL + "/clients?search=" + searchTermText;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let clientsData = JSON.parse(JSON.stringify(response.data));
        let clientsCount = clientsData.pagination.count;
        setCount(clientsCount);
        setClients(clientsData.clients);

        let numPages = Math.ceil(clientsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("searchChats err = " + err.message));
  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage);
    filterChats(selectedPage);
  };

  const filterChats = (selectedPage) => {
    var accessToken = localStorage.getItem("struts_lm_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url = apiBaseURL + "/clients?page=" + selectedPage + "&per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let clientsData = JSON.parse(JSON.stringify(response.data));
        let clientsCount = clientsData.pagination.count;
        setCount(clientsCount);
        setClients(clientsData.clients);

        let numPages = Math.ceil(clientsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter Clients err = " + err.message));
  };

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title" style={{ color: "#404040" }}>
            Clients
          </h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "2px", marginTop: "-50px" }}
            >
              <nav
                className="breadcrumb has-arrow-separator"
                aria-label="breadcrumbs"
                style={{ fontSize: "9pt" }}
              >
                <ul>
                  <li>
                    <Link
                      to="/dashboard"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/clients"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Clients
                    </Link>
                  </li>
                </ul>
              </nav>

              <div className="columns">
                <div className="column">
                  <b>Clients ({count})</b>
                  &nbsp;&nbsp;
                  <Link
                    to="/clients-documentation"
                    style={{
                      color: "#00d1b2",
                      fontWeight: "bold",
                      fontSize: "9pt",
                    }}
                  >
                    Info
                  </Link>
                </div>
                <div className="column"></div>
                <div className="column"></div>
                <div className="column">
                  <div className="bd-notification2 is-primary is-pulled-right">
                    <Link to="/clients/create">
                      <button className="button is-link">
                        Register New Client
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchChats(e.target.value)}
                />
              </p>
              <br />
              <table
                className="table is-striped is-hoverable"
                style={{ width: "100%" }}
              >
                <thead style={{ backgroundColor: "#D0D0D0" }}>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Location</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tfoot style={{ backgroundColor: "#D0D0D0" }}>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Location</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </tfoot>
                <tbody>
                  {clients.map((client) => (
                    <tr key={client.id}>
                      <th>{client.name}</th>
                      <td>{client.email}</td>
                      <td>{client.location}</td>
                      <td>{client.createdAt.substring(0, 10)}</td>
                      <th>
                        <Link to={{ pathname: `/clients/${client.id}` }}>
                          <span
                            className="icon has-text-primary"
                            data-tooltip="View"
                          >
                            <i
                              className="fas fa-eye is-primary"
                              data-tooltip="View"
                            ></i>
                          </span>
                        </Link>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div
                className="container pagination is-centered"
                style={{
                  margin: "0 auto",
                  width: "max-content",
                }}
              >
                <Pagination
                  pages={pageCount}
                  currentPage={currentPage}
                  onChange={(page) => handlePageClick(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Clients;
