import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const CreateAPIKey = () => {
  const navigate = useNavigate();

  const [keyName, setKeyName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");

  const getUser = async () => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("struts_lm_access_token");
    var userID = localStorage.getItem("struts_lm_user_id");

    try {
      const res = await axios({
        url: apiBaseURL + "/users/" + userID,
        headers: {
          "x-strutsai-token": accessToken,
          "x-strutsai-application": "user",
        },
        method: "GET",
      });
      console.log(res);
    } catch (err) {
      console.error("getUser err = " + err.message);
    }
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (!accessToken) {
      navigate("/");
    }

    getUser();

    window.scrollTo(0, 0);
  }, [navigate]);

  const generateAPIKey = (event) => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("struts_lm_access_token");

    setIsLoading(true);
    axios({
      url: `${apiBaseURL}/api_keys`,
      method: "POST",
      withCredentials: false,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
        "x-strutsai-application": "user",
        "x-client-identifier": "web",
      },
      data: {
        name: keyName,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.api_key.id) {
          var successMessage = "API Key created successfully.";
          setSuccessAlert(successMessage);
          setErrorAlert("");
        } else {
          setErrorAlert("Failed to Generate API Key.");
          setSuccessAlert("");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorAlert("Failed to Generate API Key. " + error);
        setSuccessAlert("");
      });
  };

  let alert;
  if (errorAlert.length > 0) {
    alert = (
      <div className="notification is-danger">&nbsp;&nbsp;{errorAlert} </div>
    );
  } else if (successAlert.length > 0) {
    alert = (
      <div className="notification is-success">&nbsp;&nbsp;{successAlert} </div>
    );
  }

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Generate API Key</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-half"
              style={{ marginBottom: "2px", marginTop: "-50px" }}
            >
              <nav
                className="breadcrumb has-arrow-separator"
                aria-label="breadcrumbs"
                style={{ fontSize: "9pt" }}
              >
                <ul>
                  <li style={{ color: "primary" }}>
                    <Link
                      to="/dashboard"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/api-keys"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      API Keys
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/api-keys/create"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Generate API Key
                    </Link>
                  </li>
                </ul>
              </nav>
              <p>
                <b>Generate API Key</b>
              </p>
              <br />
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Name</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Name"
                        name="key_name"
                        onChange={(e) => setKeyName(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <br />

                  {!isLoading && (
                    <div className="field">
                      <button
                        className="button is-link"
                        onClick={generateAPIKey}
                      >
                        Generate
                      </button>
                    </div>
                  )}

                  <br />
                  {alert}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateAPIKey;
