import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";

const AITrainingDocumentation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">AI Training</h2>

              <p className="">
                You can train your AI in the{" "}
                <Link to="/ai-training" style={{ color: "#00d1b2" }}>
                  AI Training
                </Link>{" "}
                section. Your endpoints and chat widget will be trained on the
                data that you provide, and will respond according to that
                content.
              </p>

              <br />

              <p>
                With the custom on premise setup, we can customize the data
                input locations, either via files or database connection so that
                you can use your existing data with AI as is.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AITrainingDocumentation;
