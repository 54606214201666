import React, { useEffect, useState } from "react";
import WebNavbar from "../components/web_navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer";

const SignUp = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_lm_access_token");
    if (accessToken) {
      navigate("/dashboard");
    }

    window.scrollTo(0, 0);
    if (
      localStorage.getItem("welcome_message") &&
      localStorage.getItem("welcome_message").length > 0
    ) {
      setSuccessAlert(localStorage.getItem("welcome_message"));
      localStorage.setItem("welcome_message", "");
    }
  }, [navigate]);

  const registerUser = (event) => {
    // Prevent page reload
    event.preventDefault();
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

    if (email === "") {
      setErrorAlert("Email required!");
      setSuccessAlert("");
      return;
    }

    if (firstName === "" || lastName === "") {
      setErrorAlert("First and Last names required!");
      setSuccessAlert("");
      return;
    }

    if (password === "" || password !== confirmPassword) {
      setErrorAlert("Passwords do not match!");
      setSuccessAlert("");
      return;
    }

    setIsLoading(true);
    axios({
      url: `${apiBaseURL}/users`,
      method: "POST",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-strutsai-application": "user",
        "x-client-identifier": "web",
      },
      data: {
        email: email,
        first_name: firstName,
        last_name: lastName,
        password: password,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.user.id) {
          var welcomeMessage = "Signup successful. Login to proceed.";
          setSuccessAlert(welcomeMessage);
          setErrorAlert("");
          localStorage.setItem("welcome_message", welcomeMessage);
          // redirect to login after a successful signup
          navigate("/login");
        } else {
          setErrorAlert("Signup failed.");
          setSuccessAlert("");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorAlert("Signup failed.");
        setSuccessAlert("");
      });
  };

  let alert;
  if (errorAlert.length > 0) {
    alert = (
      <div className="notification is-danger">&nbsp;&nbsp;{errorAlert} </div>
    );
  } else if (successAlert.length > 0) {
    alert = (
      <div className="notification is-success">&nbsp;&nbsp;{successAlert} </div>
    );
  }

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="columns">
          <div className="column"></div>
          <div className="column is-one-third">
            <form onSubmit={registerUser}>
              <div
                className="container"
                style={{
                  borderRadius: "3px;padding: 15px",
                }}
              >
                <div className="field">
                  <label className="label">Email</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Enter Email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">First name</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Enter First name"
                      name="firstName"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Last name</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Enter Last name"
                      name="lastName"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Password</label>
                  <div className="control">
                    <input
                      className="input"
                      type="password"
                      placeholder="Enter Password"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Confirm Password</label>
                  <div className="control">
                    <input
                      className="input"
                      type="password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          registerUser();
                        }
                      }}
                      required
                    />
                  </div>
                </div>

                {!isLoading && (
                  <div className="field">
                    <button className="button is-link" onClick={registerUser}>
                      Sign Up
                    </button>
                  </div>
                )}
              </div>
            </form>

            <br />
            {alert}
          </div>
          <div className="column"></div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SignUp;
